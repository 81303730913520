<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Fundamental Principles of Explosives Safety: Thorough & Accurate Process Hazards Analysis (PHA)"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <Paragraph
          ><p>
            A process hazards analysis (PHA) is a fundamental part to any
            company and process. A PHA is a set of systematic steps that are
            done on an industrial process to assess the potential hazards for a
            given process. At SMS we are experienced in hazards analysis and
            have conducted thorough analysis ranging from large scale process
            down to small scale operations.
          </p>
          <p>
            <strong>Phase 1 Design Hazards Analysis (DHA)</strong><br />
            When conducting a PHA there are similar phases to that of creating a
            new product or designing a new machine. There is an initial concept
            phase or Design Hazards Analysis (DHA) phase. This phase is mainly
            based on equipment design, facility, and operational information. In
            this step the safety related equipment design issues are looked at
            and addressed. This step takes place before equipment is fabricated
            or during the fabrication process.
          </p>
          <p>
            <strong>Phase 2 Operational Hazards Analysis (OHA)</strong><br />
            The next phase to a PHA is the Operational Hazards Analysis (OHA).
            As the name suggests, this is a hazard analysis done through startup
            and into the production step. The OHA is done through an on-site
            review of operations and special consideration is placed on
            operational changes as well as operator interaction with equipment
            and the environment that they are in. A special effort is done to
            ensure that, not only the PHA, the DHA is kept up to date and that
            together the Design Hazards Analysis (DHA) and the Operational
            Hazards Analysis (OHA) can combine to form a finished PHA.
          </p>
          <p>
            <strong>Phase 3 Scheduled Review</strong><br />
            As process evolve and change so too should a well designed PHA. Most
            of these changes are to be done through the management of change
            program (see 29 CFR 1910.119(I)) but can also be done through a PHA
            revalidation. Special consideration should be made to keep the
            hazards analysis up to date and effective. By law, PHA’s are
            required to be revalidated every 5 years (see 29 CFR
            1910.119(e)(6)).
          </p>
          <p>
            In summary, hazards analysis can be an effective way to prevent
            accidents and maintain safe working environments for both workers
            and equipment. Just like care and consideration is done when testing
            explosives, so too should we take that same care in Process Safety
            and its analysis.
          </p>
        </Paragraph>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <img src="@/assets/Resources/Blog/DHAOHA-min.png" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Accurate Process Hazard Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "The three phases of a thorough and effective PHA is a design hazards analysis, an operational hazards analysis, and a scheduled review. Each phase addresses all potential failure configurations, including equipment design, operator interaction, and any interactions between the equipment and environment. The Scheduled review results in follow-through with initial recommendations and update the PHA with newly presented hazards resulting from changes to the process."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
